const AutoRowsNumbers = function (element, config) {
  this.$element = $(element)
  this.config = config
}

AutoRowsNumbers.prototype = {
  constructor: AutoRowsNumbers,

  init () {
    this.prepareWrappers()
    this.insertWrappers()

    this.$element.on('keydown', {that: this}, this.inputText)
    this.$element.on('scroll', {that: this}, this.syncScroll)
    this.inputText({data: {that: this}})
  },

  reset () {
    this.cleanup()
    this.prepareWrappers()
    this.insertWrappers()
    this.inputText({data: {that: this}})
  },

  cleanup () {
    const wrap = this.$element.closest('.textarea-group')
    this.$element.attr('style', '').insertBefore(wrap)

    wrap.remove()
    this.$group.remove()
    this.$ol.remove()
    this.$wrap.remove()
  },

  insertWrappers () {
    this.$element.wrap(this.$group)
    this.$ol.insertBefore(this.$element)
    this.$ol.wrap(this.$wrap)
  },

  prepareWrappers () {
    const config = this.config

    this.$group = $('<div/>', { 'class': 'textarea-group' })
    this.$ol = $('<div/>', { 'class': 'textarea-rows' })
    this.$wrap = $('<div/>', { 'class': 'textarea-wrap' })

    this.$group.css({
      'width': this.$element.outerWidth(true) + 'px',
      'display': config.display,
    })
    this.$ol.css({
      'color': config.color,
      'width': config.width,
      'height': this.$element.height(),
      'font-size': this.$element.css('font-size'),
      'line-height': this.$element.css('line-height'),
      'position': 'absolute',
      'overflow': 'hidden',
      'margin': 0,
      'padding': 0,
      'text-align': 'center',
      'font-family': this.$element.css('font-family'),
    })
    this.$wrap.css({
      'padding': ((this.$element.outerHeight() -
                   this.$element.height()) / 2) + 'px 0',
      'background-color': config.bgColor,
      'position': 'absolute',
      'box-sizing': 'border-box',
      'margin': 0,
      'width': config.width,
      'height': this.$element.height() + 'px',
    })
    this.$element.css({
      'white-space': 'pre',
      'resize': 'none',
      'margin': 0,
      'box-sizing': 'border-box',
      'padding-left': (parseInt(config.width) -
                       parseInt(this.$element.css('border-left-width')) +
                       parseInt(this.$element.css('padding-left'))) + 'px',
    })
  },

  inputText (event) {
    var that = event.data.that

    setTimeout(function () {
      var value = that.$element.val()
      value.match(/\n/g)
        ? that.updateLine(value.match(/\n/g).length + 1)
        : that.updateLine(1)
      that.syncScroll({data: {that}})
    }, 0)
  },

  updateLine (count) {
    var that = this
    that.$element
    that.$ol.html('')

    for (var i = 1; i <= count; i++) {
      that.$ol.append('<div>' + i + '</div>')
    }
  },

  syncScroll (event) {
    var that = event.data.that
    that.$ol.children().eq(0)
      .css('margin-top', -(that.$element.scrollTop()) + 'px')
  },
}

$.fn.setTextareaCount = function (option) {
  var config = {}
  option = arguments[0] ? arguments[0] : {}
  config.color = option.color ? option.color : '#FFF'
  config.width = option.width ? option.width : '30px'
  config.bgColor = option.bgColor ? option.bgColor : '#999'
  config.display = option.display ? option.display : 'block'

  return this.each(function () {
    var $this = $(this)
    var data = $this.data('autoRowsNumbers')

    if (!data) {
      $this.data('autoRowsNumbers', (data = new AutoRowsNumbers($this, config)))
    }

    if (typeof option === 'string') {
      if (option === 'reset') data.reset()
    } else {
      data.init()
    }
  })
}
